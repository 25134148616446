.host {
  background-color: #f5f5f5;
}

.form-signin {
  max-width: 400px;
}

.topbar-divider {
  width: 0;
  border-right: 1px solid #caccd1;
  height: 1.9rem;
  margin: auto 1rem;
}
