body,
html {
  margin: 0 0 0 0px;
}

.embed {
  height: 100vh;
}

.embed-wrapper {
  position: relative;
  height: 100vh !important;
  padding-bottom: inherit !important;
  max-height: none !important;
}

.plyr {
  height: 100%;
}
