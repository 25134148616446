@font-face {
  font-family: 'ChristmasCard-Regular';
  src: url('../../assets/samsung/ChristmasCard-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

.samsung {
  background-color: #020e21;
  background-image: url('../../assets/samsung/background-dark.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;

  color: #fff;

  .text-muted {
    color: #dddddd !important;
  }

  .box {
    background-color: rgba(0, 0, 0, 0.65);
    padding: 30px;

    border-width: 3px;
    border-style: solid;
    border-image-slice: 1 !important;
    border-image: linear-gradient(to right, #86613e, #f6d797, #86613e) 1 100%;

    // background: linear-gradient(to right, red, purple);
    // border-style: solid;
    // border-width: 5px;
    // border-color: #0f0;
    // border-color: -webkit-linear-gradient(0deg, #86613e, #f6d797, #86613e);
  }

  h1 {
    font-size: 7rem !important;
  }

  h2 {
    font-size: 5.5rem !important;
  }

  h3 {
    font-size: 4.5rem !important;
  }

  h4 {
    font-size: 3.5rem !important;
  }

  h6 {
    font-size: 2rem !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'ChristmasCard-Regular';
    line-height: 1;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a {
    // color: #ccc !important;
    background-clip: text;
    background: -webkit-linear-gradient(0deg, #86613e, #f6d797, #86613e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .btn-primary {
    color: #000000;
    background: -webkit-linear-gradient(
      0deg,
      #86613e,
      #f6d797,
      #86613e,
      #f6d797,
      #86613e
    );
    font-weight: bold;
    border-color: transparent !important;
    text-transform: uppercase !important;
  }

  .form-control {
    background-color: #000 !important;
    color: #ffffff !important;
  }

  .list-group-item,
  .card {
    background-color: #000000 !important;
    border-width: 1px;
    border-style: solid;
    border-image-slice: 1 !important;
    border-image: linear-gradient(to right, #86613e, #f6d797, #86613e) 1 100%;
  }

  footer > .row {
    background-color: rgba(0, 0, 0, 0.65);
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
  }
}
