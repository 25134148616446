@import './registration';
@import './player';
@import './host';
@import './chat';
@import './samsung';

@media (orientation: landscape) {
  .px-6 {
    padding-left: $spacer * 6 !important;
    padding-right: $spacer * 6 !important;
  }
}

.language-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999999;

  .dropdown-menu {
    min-width: 1rem;
  }

  .dropdown-item {
    padding: 0.25rem 0.44rem;
  }
}

.bg-black {
  background-color: black !important;
}
