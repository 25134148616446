.transition-opacity {
  -webkit-transition: opacity 0.2s ease-out;
  -moz-transition: opacity 0.2s ease-out;
  -ms-transition: opacity 0.2s ease-out;
  -o-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
}

.transition-blur {
  -webkit-transition: 0.2s -webkit-filter ease-out;
  -moz-transition: 0.2s -moz-filter ease-out;
  -ms-transition: 0.2s -moz-filter ease-out;
  -o-transition: 0.2s -o-filter ease-out;
  transition: 0.2s filter ease-out;
}

.player-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  max-height: 40vh;

  .mask {
    @extend .transition-opacity;
    @extend .transition-blur;

    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    .player {
      @extend .transition-opacity;
      @extend .transition-blur;

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .video-stream {
        top: 0 !important;
      }
    }

    .player-blur {
      filter: blur(10px);
      -webkit-filter: blur(10px);
      -moz-filter: blur(10px);
      -o-filter: blur(10px);
      -ms-filter: blur(10px);

      pointer-events: none;
    }
  }

  .mask-dimmed {
    opacity: 0.3;
  }

  .dynamic-wrapper::-webkit-scrollbar {
    display: none;
  }

  .dynamic-wrapper {
    @extend .transition-opacity;

    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    overflow: scroll;
    opacity: 0;

    pointer-events: none;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    @media (max-width: 768px) {
      h2 {
        font-size: 1rem;
      }
    }

    // @media (min-width: 768px) {
    //   h1 {
    //     font-size: 1vw;
    //   }
    // }
  }

  .overlay-active {
    @extend .transition-opacity;

    opacity: 1 !important;

    pointer-events: all;
  }
}

.plyr--fullscreen-fallback {
  z-index: 9999998 !important;
}
