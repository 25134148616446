@font-face {
  font-family: 'Bostik';
  font-style: normal;
  font-weight: normal;
  src: url('./bostikregular-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Bostik';
  font-style: normal;
  font-weight: bold;
  src: url('./bostikmedium-webfont.woff2') format('woff2');
}

.bostik-body {
  background-color: #04284c;
  color: #fff;
  font-family: 'Bostik';

  .btn-outline-warning {
    color: #fdc300;
    border-color: #fdc300;
  }

  .text-muted {
    color: #ccc !important;
  }
}
